import React, { useContext } from 'react'
import Layout from '../../components/customlayout'

import AuthContext from "../../context/AuthContext"

import Guilds from "../../components/guild"

const DashboardPage = () => {
    const { isLogged, me, refreshMutual } = useContext(AuthContext);
    if (!me.mutualGuilds) refreshMutual();
    return (
        <Layout>
            <main className='container py-4'>
                <button className='btn btn-info' onClick={refreshMutual}
                    style={{ display: 'flex' }}>REFRESH</button>
                {isLogged ?
                    <Guilds guilds={me.mutualGuilds} />
                    :
                    'login required'}
            </main>
        </Layout>
    )
}

export default DashboardPage